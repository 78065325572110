@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.snackbarcustom {
  background: rgb(173, 165, 165) !important;
  height:10000px !important
}

#mi-snackbar::before {
/*  width: 100%;
  position: relative;
  content: "Mensaje";
  background-color: #ffba10;
  color:yellow;
  padding:50px;
  border-color: black;
  border-style: dotted;
  margin-left: 50%;*/
}

#snackbarprincipal {
  /*background: red !important;*/
}

#mi-snackbar{
    /*color:blue*/
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-personal-data button {
  border: solid 1px #C2C1C1;
  position: relative;
  left: -8px;
}

.select-personal-data label::after, .select-personal-data label::before {
  content: "";
}
.select-personal-data span img, .select-personal-data label {
  display: none !important;
}

.select-mui .MuiFilledInput-adornedEnd, .select-mui .MuiFilledInput-adornedEnd:hover, .select-mui .MuiFilledInput-adornedEnd:active,
.select-mui .MuiFilledInput-adornedEnd:focus {
  background: white;
}

.mui-select-tailwind button {
  height: 35px;
  border-radius: 5px;
}

.mui-select-tailwind button span {
  margin-left: 10px;
  margin-top: -5px;
}

.edition-date-picker input {
  border: solid 1px #C2C1C1 !important;
}

.form-tramite-anexo input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 10px;
  margin-top: -5px;
}